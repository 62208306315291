import { ErrorInfo, ReactNode } from 'react'
import { getSessionId } from './Utils/common'
import { getClientEnvironment, logger } from '@dfds-pax/common'
import { LoggingInterfaces } from '@dfds-pax/logging-provider'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  children: ReactNode
}

interface State {
  error?: string
  eventId?: string
  errorInfo?: string
  hasError: boolean
  status?: string
  correlationId?: string
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, correlationId: uuidv4() }
  }

  static getDerivedStateFromError() {
    return { hasError: true, correlationId: uuidv4() }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const correlationId = uuidv4()
    this.setState({ correlationId: correlationId })
    var payload: LoggingInterfaces.LogProps = {
      message: error?.message,
      messageTemplate: 'Frontend error',
      level: 'Error',
      fields: {
        correlationId,
        application: 'travel-search',
        environment: getClientEnvironment(),
        frontend: true,
        path: window.location.pathname,
        referer: document.referrer,
        userAgent: navigator.userAgent,
        sessionId: getSessionId(),
        error: { error: error?.name, stackTrace: errorInfo?.componentStack! },
      },
    }
    logger('Error', getClientEnvironment(), payload)
  }

  render() {
    if (this.state.hasError) {
      return <div style={{ color: 'white' }}>An error happened. Please refresh page.</div>
    }
    return this.props.children
  }
}

export default ErrorBoundary
