import useSWR from 'swr'
import { fetcher, getBaseApi, getLocaleFromUrlOrQuery } from '../Utils/common'
import { LoadDataFromParams } from '../Utils/url-helpers'

export const useRoutes = (selectedRoute?: Route, oneway?: boolean, minibookId?: string): UseRoutesResult => {
  const baseApi = getBaseApi()
  const { queryData } = LoadDataFromParams()
  const locale = getLocaleFromUrlOrQuery()

  const { data, isLoading } = useSWR(minibookId ? `${baseApi}/routes?locale=${locale}&minibookId=${minibookId}` : null, { fetcher })
  const routesApiData = data as RoutesResponse
  let _selectedRoute =
    !selectedRoute && routesApiData
      ? routesApiData?.defaultRoute && !queryData.routeCode
        ? { ...(routesApiData.defaultRoute ?? routesApiData?.categories[0].routes[0]) }
        : queryData.routeCode &&
            routesApiData &&
            routesApiData.categories.find((c) => c.routes.find((r) => r.routeCode === queryData.routeCode))
          ? routesApiData.categories
              .find((c) => c.routes.find((r) => r.routeCode === queryData.routeCode))
              ?.routes.find((r) => r.routeCode === queryData.routeCode)
          : routesApiData?.categories[0].routes[0]
      : undefined
  if (!_selectedRoute && selectedRoute) _selectedRoute = selectedRoute
  let routeCode = _selectedRoute?.routeCode
  if (!routeCode) routeCode = routesApiData?.defaultRoute?.routeCode
  else if (!routeCode) routeCode = routesApiData?.categories[0].routes[0]?.routeCode

  const unloCode = tempRouteMap.find((x) => x.seabook === routeCode?.toUpperCase())?.route

  return {
    isLoading,
    routesApiData,
    isOneway: oneway ?? routesApiData?.oneWay,
    selectedRoute: _selectedRoute,
    selectedUnloCode: unloCode,
    campaignPassengers: routesApiData?.campaignPassengers ?? 0,
  }
}

export interface UseRoutesResult {
  isLoading: boolean
  routesApiData: RoutesResponse
  isOneway: boolean
  selectedRoute: Route | undefined
  selectedUnloCode: string | undefined
  campaignPassengers: number
}

export interface RoutesResponse {
  categories: Category[]
  defaultRoute: Route
  campaignPassengers: number
  oneWay: boolean
  defaultToNoVehicle: boolean
  offerOrProductCode?: string
  offerOrProductText?: string
}

export interface Category {
  name: string
  routes: Route[]
}
export interface Route {
  name: string
  salesOwner: number
  routeCode: string
  returnRequired: boolean
}

const tempRouteMap = [
  { seabook: 'CADV', route: 'FRCQF-GBDVR' },
  { seabook: 'DKDV', route: 'FRDKK-GBDVR' },
  { seabook: 'DVCA', route: 'GBDVR-FRCQF' },
  { seabook: 'DVDK', route: 'GBDVR-FRDKK' },
  { seabook: 'KPPA', route: 'SEKPS-EEPLA' },
  { seabook: 'PAKP', route: 'EEPLA-SEKPS' },
  { seabook: 'ANNE', route: 'NLIJM-GBTYN' },
  { seabook: 'ANOV', route: 'NLIJM-NLAMS' },
  { seabook: 'CHDA', route: 'DKCPH-DKCPH' },
  { seabook: 'CHOS', route: 'DKCPH-NOOSL' },
  { seabook: 'COAR', route: 'DKCPH-DKAAR' },
  { seabook: 'COCO', route: 'DKCPH-DKCPH' },
  { seabook: 'DPNH', route: 'FRDPE-GBNHV' },
  { seabook: 'FHOS', route: 'DKFDH-NOOSL' },
  { seabook: 'HKPA', route: 'FIHKO-EEPLA' },
  { seabook: 'KHKL', route: 'SEKAN-LTKLJ' },
  { seabook: 'KIKL', route: 'DEKEL-LTKLJ' },
  { seabook: 'KLKH', route: 'LTKLJ-SEKAN' },
  { seabook: 'KLKI', route: 'LTKLJ-DEKEL' },
  { seabook: 'NEAN', route: 'GBTYN-NLIJM' },
  { seabook: 'NHDP', route: 'GBNHV-FRDPE' },
  { seabook: 'OSCH', route: 'NOOSL-DKCPH' },
  { seabook: 'OSFH', route: 'NOOSL-DKFDH' },
  { seabook: 'PAHK', route: 'EEPLA-FIHKO' },
  { seabook: 'DKRO', route: 'FRDKK-IEROE' },
  { seabook: 'RODK', route: 'IEROE-FRDKK' },
  { seabook: 'SHSM', route: 'JESTH-FRSML' },
  { seabook: 'SMSH', route: 'FRSML-JESTH' },
  { seabook: 'SHPO', route: 'JESTH-GBPOO' },
  { seabook: 'POSH', route: 'GBPOO-JESTH' },
  { seabook: 'SHPM', route: 'JESTH-GBPME' },
  { seabook: 'PMSH', route: 'GBPME-JESTH' },
]
